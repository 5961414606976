import endpoints from 'api/endpoints';
import { api } from 'api/rest';

export class ExternalGameService {
  static async findAll(params?: Record<string, any>) {
    const response = await api.get(endpoints.externalGames, { params });
    return response;
  }
  static async create(data) {
    const response = await api.post(endpoints.externalGames, data);
    return response;
  }
  static async delete(id) {
    const response = await api.delete(`${endpoints.externalGames}/${id}`);
    return response;
  }
  static async update(id, data) {
    const response = await api.put(`${endpoints.externalGames}/${id}`, data);
    return response;
  }
}
