import React, { useEffect, useState } from 'react';
// react plugin for creating charts
import ChartistGraph from 'react-chartist';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
// @material-ui/icons
import DateRange from '@material-ui/icons/DateRange';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import { useAPI } from 'hooks/use-api';

import endpoints from 'api/endpoints';

import { dailySalesChart } from 'variables/charts.js';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

import Chartist from 'chartist';

const useStyles = makeStyles(styles);

function GamesStats({ classes, games }) {
  return (
    <GridItem xs={12} sm={6} md={3}>
      <Card>
        <CardHeader color="success" stats icon>
          <CardIcon color="success">
            <Icon>equalizer</Icon>
          </CardIcon>
          <p className={classes.cardCategory}>Всего игр</p>
          <h3 className={classes.cardTitle}>{games.total}</h3>
        </CardHeader>
        <CardFooter stats>
          <div className={classes.stats}>Таймаут: {games.timeout}</div>
          <div className={classes.stats}>Мат: {games.capture}</div>
          <div className={classes.stats}>Сдался: {games.resign}</div>
          <div className={classes.stats}>Бездействие: {games.inactivity}</div>
        </CardFooter>
      </Card>
    </GridItem>
  );
}

function GamesDayStats({ classes, games }) {
  return (
    <GridItem xs={12} sm={6} md={3}>
      <Card>
        <CardHeader color="success" stats icon>
          <CardIcon color="success">
            <Icon>equalizer</Icon>
          </CardIcon>
          <p className={classes.cardCategory}>Кол-во игр (сегодня)</p>
          <h3 className={classes.cardTitle}>{games.totalToday}</h3>
        </CardHeader>
        <CardFooter stats>
          <div className={classes.stats}>Вчера: {games.totalYesterday}</div>
        </CardFooter>
      </Card>
    </GridItem>
  );
}

function UsersStats({ classes, users }) {
  return (
    <GridItem xs={12} sm={6} md={3}>
      <Card>
        <CardHeader color="success" stats icon>
          <CardIcon color="success">
            <Icon>groups</Icon>
          </CardIcon>
          <p className={classes.cardCategory}>Всего пользователей</p>
          <h3 className={classes.cardTitle}>{users.total}</h3>
        </CardHeader>
        <CardFooter stats>
          <div className={classes.stats}>Заблокировано: {users.blocked}</div>
          <div className={classes.stats}>С балансом: {users.positive}</div>
        </CardFooter>
      </Card>
    </GridItem>
  );
}

function RevenueStats({ classes, revenue }) {
  return (
    <GridItem xs={12} sm={6} md={3}>
      <Card>
        <CardHeader color="success" stats icon>
          <CardIcon color="success">
            <Icon>money</Icon>
          </CardIcon>
          <p className={classes.cardCategory}>Доход с комиссий</p>
          <h3 className={classes.cardTitle}>€{revenue.totalToday.toFixed(2)}</h3>
        </CardHeader>
        <CardFooter stats>
          <div className={classes.stats}>
            <DateRange />
            За все время
          </div>
        </CardFooter>
      </Card>
    </GridItem>
  );
}

function WebsocketStats({ classes, connectionCount }) {
  return (
    <GridItem xs={12} sm={6} md={3}>
      <Card>
        <CardHeader color="success" stats icon>
          <CardIcon color="success">
            <Icon>groups</Icon>
          </CardIcon>
          <p className={classes.cardCategory}>Количество websocket соединений</p>
          <h3 className={classes.cardTitle}>{connectionCount}</h3>
        </CardHeader>
        <CardFooter stats>
          <div className={classes.stats}>
            <DateRange />
            На данный момент
          </div>
        </CardFooter>
      </Card>
    </GridItem>
  );
}

export default function Dashboard() {
  const classes = useStyles();

  const [{ data, isLoading, isError }, doGetStats] = useAPI();

  const [gamesStats, setGamesStats] = useState({ labels: [], series: [[]] });
  const [maxGames, setMaxGames] = useState(0);
  const [websocketConnCount, setWebsocketConnCount] = useState(0)

  useEffect(() => {
    doGetStats(endpoints.stats);
  }, []);

  useEffect(() => {
    if (data && data.games && data.games.stats) {
      const stats = data.games.stats.reverse();
      const totals = stats.map((s) => s.total);
      setGamesStats({ labels: stats.map((s) => s.date), series: [totals] });
      setMaxGames(Math.max.apply(null, totals));
      setWebsocketConnCount(data.websocketConnections.value)
    }
  }, [data]);

  useEffect(() => {
    console.log('gamesStats', gamesStats);
  }, [gamesStats]);

  return (
    <div>
      <GridContainer>
        {data && <UsersStats classes={classes} users={data.users} />}

        {data && <GamesStats classes={classes} games={data.games} />}

        {data && <GamesDayStats classes={classes} games={data.games} />}

        {data && <RevenueStats classes={classes} revenue={data.revenue} />}

        {data && <WebsocketStats classes={classes} connectionCount={websocketConnCount} />}
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={gamesStats}
                type="Line"
                options={{
                  lineSmooth: Chartist.Interpolation.cardinal({
                    tension: 0,
                  }),
                  low: 0,
                  high: maxGames,
                  chartPadding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  },
                }}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Количество игр по датам</h4>
            </CardBody>
            <CardFooter chart></CardFooter>
          </Card>
        </GridItem>
        {/*
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="warning">
              <ChartistGraph
                className="ct-chart"
                data={emailsSubscriptionChart.data}
                type="Bar"
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Email Subscriptions</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="danger">
              <ChartistGraph
                className="ct-chart"
                data={completedTasksChart.data}
                type="Line"
                options={completedTasksChart.options}
                listener={completedTasksChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Completed Tasks</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <CustomTabs
            title="Tasks:"
            headerColor="primary"
            tabs={[
              {
                tabName: 'Bugs',
                tabIcon: BugReport,
                tabContent: (
                  <Tasks checkedIndexes={[0, 3]} tasksIndexes={[0, 1, 2, 3]} tasks={bugs} />
                ),
              },
              {
                tabName: 'Website',
                tabIcon: Code,
                tabContent: <Tasks checkedIndexes={[0]} tasksIndexes={[0, 1]} tasks={website} />,
              },
              {
                tabName: 'Server',
                tabIcon: Cloud,
                tabContent: <Tasks checkedIndexes={[1]} tasksIndexes={[0, 1, 2]} tasks={server} />,
              },
            ]}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Employees Stats</h4>
              <p className={classes.cardCategoryWhite}>New employees on 15th September, 2016</p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                tableHead={['ID', 'Name', 'Salary', 'Country']}
                tableData={[
                  ['1', 'Dakota Rice', '$36,738', 'Niger'],
                  ['2', 'Minerva Hooper', '$23,789', 'Curaçao'],
                  ['3', 'Sage Rodriguez', '$56,142', 'Netherlands'],
                  ['4', 'Philip Chaney', '$38,735', 'Korea, South'],
                ]}
              />
            </CardBody>
          </Card>
              </GridItem>*/}
      </GridContainer>
    </div>
  );
}
