import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import qs from 'qs';
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_FullScreenToggleButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { ColumnSort } from '@tanstack/react-table';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { errorService } from 'services/alert/services';
import { useStore } from 'hooks/use-store';
import { URLHelper } from 'shared/helpers/URLHelper';
import { useDebounce } from 'hooks/use-debounce';
import { convertIntoArray, getFilters } from 'views/Transactions';
import { mapExternalGameProviderTypeToText, mapExternalGameType, mapExternalGameTypeToText } from 'domain/external-game';
import { NumberHelper } from 'shared/helpers/NumberHelper';
import { BooleanHelper } from 'shared/helpers/BooleanHelper';

import { ExternalGameService } from '../../services/api/external-game';

import CreateOrUpdateExternalGameModal from './create-or-update-modal';
import { externalGameTypes, externalGameProviderTypes } from './constant';

const styles = {
  increaseBalance: {
    color: '#2eb92e',
  },
  decreaseBalance: {
    color: '#e01919',
  },
  takingReservedBalance: {
    color: '#707070',
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

// @ts-ignore
const useStyles = makeStyles(styles);

const ExternalGames = () => {
  const classes = useStyles();
  const [isOpenCreateOrUpdateModal, setOpenCreateOrUpdateModal] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);
  const { externalGameProviders } = useStore();

  const onRowDelete = async (rowData) => {
    if (
      !window.confirm(`Вы уверены что хотите удалить игру ${rowData.name ?? rowData.description}?`)
    ) {
      return;
    }
    try {
      await ExternalGameService.delete(rowData._id);
      await getExternalGames();
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  const onRowAdd = async (rowData) => {
    try {
      await ExternalGameService.create({
        ...rowData,
        orderOptions: {
          isNew: rowData.isNewOrder,
        },
        generalMin: NumberHelper.getNumberOrValue(rowData.generalMin, null),
        generalMax: NumberHelper.getNumberOrValue(rowData.generalMax, null),
      });
      await getExternalGames();
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  const onRowUpdate = async (_id, rowData) => {
    try {
      await ExternalGameService.update(_id, {
        ...rowData,
        orderOptions: {
          isNew: rowData.isNewOrder,
        },
        generalMin: NumberHelper.getNumberOrValue(rowData.generalMin, null),
        generalMax: NumberHelper.getNumberOrValue(rowData.generalMax, null),
      });
      await getExternalGames();
    } catch (e) {
      errorService.sendError(e.message);
    }
  };
  const externalGameProviderLookup = useMemo(() => {
    return _.reduce(
      externalGameProviders,
      (coll, provider) => {
        coll[provider._id] = provider.name;
        return coll;
      },
      {},
    );
  }, [externalGameProviders]);

  const onCreateOrUpdateModalClose = useCallback(() => {
    setSelectedGame(null);
    setOpenCreateOrUpdateModal(false);
  }, []);

  const searchQueryFilters = qs.parse(location.search.substring(1));

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: 'ID',
        accessorKey: '_id',
        Cell: ({ cell }) => {
          return cell.getValue() as string;
        },
      },
      {
        header: 'External ID',
        accessorKey: 'externalId',
        Cell: ({ cell }) => {
          return cell.getValue() as string;
        },
        enableEditing: false,
        enableSorting: false,
      },
      {
        header: 'Изображение',
        accessorKey: 'imageUrl',
        Cell: ({ cell }) => {
          return cell.getValue() as any;
        },
        enableColumnFilterModes: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: 'Название',
        accessorKey: 'name',
        Cell: ({ cell }) => {
          return cell.getValue() as string;
        },
        enableColumnFilterModes: false,
      },
      {
        header: 'Тип игры',
        accessorKey: 'type',
        filterVariant: 'multi-select' as const,
        filterSelectOptions: convertIntoArray(mapExternalGameType),
        Cell: ({ cell }) => {
          return mapExternalGameTypeToText(cell.getValue() as number) ?? cell.getValue();
        },
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        header: 'Тип провайдера',
        accessorKey: 'parentProvider',
        filterVariant: 'multi-select' as const,
        filterSelectOptions: convertIntoArray(externalGameProviderTypes),
        Cell: ({ cell }) => {
          return mapExternalGameProviderTypeToText(cell.getValue() as number) ?? cell.getValue();
        },
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        header: 'Провайдер игры',
        accessorKey: 'providerId',
        filterVariant: 'multi-select' as const,
        filterSelectOptions: convertIntoArray(externalGameProviderLookup),
        Cell: ({ cell }) => {
          return externalGameProviderLookup[cell.getValue() as string] ?? cell.getValue();
        },
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        header: 'Статус',
        accessorKey: 'enabled',
        accessorFn: (originalRow) => originalRow.enabled,
        Cell: ({ cell }) => {
          return cell.getValue() ? 'Включен' : 'Отключен';
        },
        filterVariant: 'checkbox',
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        header: 'Порядок',
        accessorKey: 'order',
        Cell: ({ cell }) => {
          return cell.getValue() as string;
        },
        enableColumnFilterModes: false,
        enableSorting: false,
      },
    ],
    [externalGameProviderLookup],
  );

  const defaultFilters = useMemo(() => {
    return columns.reduce(
      (acc, column) => {
        const searchQueryFilter = searchQueryFilters[column.accessorKey as string];
        if (searchQueryFilter) {
          acc.push({
            id: column.accessorKey as string,
            value: searchQueryFilter,
          });
        }
        return acc;
      },
      [] as { id: string; value: any }[],
    );
  }, [searchQueryFilters]);

  const defaultSorting = useMemo(() => {
    if (searchQueryFilters.orderBy) {
      return [
        {
          id: searchQueryFilters.orderBy as string,
          desc: !BooleanHelper.toBoolean(searchQueryFilters.orderDirection),
        },
      ];
    }
    return [];
  }, [searchQueryFilters]);

  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const [columnFilters, setColumnFilters] = useState<{ id: string; value: any }[]>(defaultFilters);
  const [sorting, setSorting] = useState<ColumnSort[]>(defaultSorting);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 30,
  });

  const debouncedColumnFilters = useDebounce(columnFilters, 700);

  const getExternalGames = async () => {
    try {
      setIsLoading(true);

      const filters = getFilters(columnFilters);
      const orderBy = sorting.length
        ? {
            orderBy: sorting[0].id,
            orderDirection:
              typeof sorting[0].desc === 'number'
                ? sorting[0].desc
                : BooleanHelper.toBoolean(sorting[0].desc)
                ? -1
                : 1,
          }
        : {};

      URLHelper.replaceSearchParams(
        qs.stringify({
          ...columnFilters,
          ...orderBy,
          page: pagination.pageIndex,
          pageSize: pagination.pageSize,
        }),
      );

      const { data: remoteData } = await ExternalGameService.findAll({
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
        ...filters,
        ...orderBy,
      });
      setData(remoteData.data);
      setRowCount(remoteData.total);
      setIsError(false);
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getExternalGames();
  }, [debouncedColumnFilters, pagination.pageIndex, pagination.pageSize, sorting]);

  return (
    <div>
      <CreateOrUpdateExternalGameModal
        open={isOpenCreateOrUpdateModal}
        mode={selectedGame ? 'update' : 'create'}
        externalGame={selectedGame}
        onCreate={onRowAdd}
        onUpdate={onRowUpdate}
        handleClose={onCreateOrUpdateModalClose}
      />
      <GridContainer>
        {/* @ts-ignore */}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Управление играми</p>
            </CardHeader>
            <CardBody>
              <MaterialReactTable
                columns={columns}
                data={data}
                getRowId={(row: any) => row._id}
                initialState={{
                  showColumnFilters: true,
                }}
                enableMultiSort={false}
                manualFiltering
                manualPagination
                manualSorting
                enableGlobalFilter={false}
                enableSorting
                muiToolbarAlertBannerProps={
                  isError
                    ? {
                        color: 'error',
                        children: 'Error loading data',
                      }
                    : undefined
                }
                onColumnFiltersChange={setColumnFilters}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={rowCount}
                enableColumnFilterModes={false}
                localization={MRT_Localization_RU}
                enableToolbarInternalActions
                enableRowActions
                state={{
                  columnFilters,
                  isLoading,
                  pagination,
                  showAlertBanner: isError,
                  sorting,
                }}
                renderRowActions={({ row }) => (
                  <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                    <IconButton
                      onClick={() => {
                        setSelectedGame(row.original);
                        setOpenCreateOrUpdateModal(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        onRowDelete(row.original);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
                renderTopToolbar={({ table }) => {
                  return (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Box>
                        <IconButton onClick={() => setOpenCreateOrUpdateModal(true)}>
                          <AddIcon />
                        </IconButton>
                        <MRT_ShowHideColumnsButton table={table} />
                        <MRT_FullScreenToggleButton table={table} />
                        <MRT_ToggleDensePaddingButton table={table} />
                        <MRT_ToggleFiltersButton table={table} />
                      </Box>
                    </Box>
                  );
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ExternalGames;
