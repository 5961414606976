import { EExternalGameProviderType, EExternalGameType } from './external-game.types';

export const mapExternalGameProviderType = {
  [EExternalGameProviderType.ALGNET]: 'AlgNet',
  [EExternalGameProviderType.EVENBET]: 'Evenbet',
};

export const mapExternalGameProviderTypeToText = (val: EExternalGameProviderType) => {
  return mapExternalGameProviderType[val];
};

export const mapExternalGameType = {
  [EExternalGameType.SLOTS]: 'Слоты',
  [EExternalGameType.LIVE_DEALER]: 'Дилеры',
  [EExternalGameType.SPORTS_BETTING]: 'Беттинг',
  [EExternalGameType.VIRTUAL_SPORT]: 'Виртуальный спорт',
};

export const mapExternalGameTypeToText = (val: EExternalGameType) => {
  return mapExternalGameType[val];
};
