import React, {useEffect, useMemo, useRef, useState} from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import { Select, InputLabel, MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { BeatLoader } from 'react-spinners';
import ErrorIcon from '@mui/icons-material/Error';
import {Box, Typography} from '@mui/material';
import axios from 'axios';

import { errorService } from 'services/alert/services';
import { useRanks } from 'hooks/use-ranks';

import { dialogStyles } from './styles';
import { tournamentType, isType, isMode, modes } from './utils';
import { ETournamentType } from 'domain/tournament';
import { TournamentService } from 'services/api/tournament';
import { useStore } from 'hooks/use-store';
import UploadImageInput from 'components/UploadImageInput';
import { EGameFen } from 'domain/game/game.types';
import {endpointsRoot} from "../../api/endpoints";
import {useAuth} from "../../hooks/use-auth";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const availableValues = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024];

const defaultPrizes = [
  // {
  //   amount: 0,
  //   rating: 0,
  //   place: 1,
  //   range: null,
  // },
];

const defaultBetsMap = [
  {
    minute: 0,
    bet: 1,
  },
  {
    minute: 15,
    bet: 2,
  },
  {
    minute: 30,
    bet: 3,
  },
  {
    minute: 45,
    bet: 4,
  },
];


const defaultPauses = [
  {
    minute: 30,
    amount: 2,
  },
];

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, isDecimal, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: +values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
};

const timeControlList = [
  {
    time: 1,
    increment: 1,
    label: '1',
  },
  {
    time: 2,
    increment: 0,
    label: '2',
  },
  {
    time: 3,
    increment: 3,
    label: '3',
  },
  {
    time: 5,
    increment: 5,
    label: '5',
  },
  {
    time: 10,
    increment: 0,
    label: '10',
  },
  {
    time: 20,
    increment: 0,
    label: '20',
  },
];

const defaultTimeControl = { time: 5, increment: 5 };

const ErrorMessage = ({ message }) => {
  return <div style={{ color: 'red', fontSize: 12 }}>{message}</div>;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: any;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CreateOrUpdateTournamentModal = ({ open, handleClose, onSubmit, mode, tournamentId }) => {
    const auth = useAuth();
  const styles = dialogStyles();
  const { data: ranks, isLoading, isError } = useRanks();
  const [tournament, setTournament] = useState<any>(null);
  const [isAddingUser, setAddingUser] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [mes, setMes] = useState('');
  const { tournamentTemplates } = useStore();

  const inputRef = useRef(null);


  const sendForm = (eve: any) => {
    eve.preventDefault();


    if (inputRef.current) {
        const value = (inputRef.current as HTMLInputElement).value;

        if (value) {
            setLoading(true);
            axios({
                method: 'post',
                url: endpointsRoot + '/api/3/tournament/add-user',
                headers:  { 'Content-Type': 'application/json', Authorization: `Bearer ${auth.token}` },
                data: {
                    name: value,
                    tournamentId: tournamentId
                },
            }).then((res) => {
                const user = res.data;


                setLoading(false);
                if (user.err) {
                    setMes(user.err);
                } else {
                    setMes(user.nickname + ' ' + user.email + ' added');
                }

                const tm = setTimeout(() => {
                    setMes('');

                    clearTimeout(tm);
                }, 2000);

            }).catch((err) => {
                setMes(err.message);
                setLoading(false);

                const tm = setTimeout(() => {
                    setMes('');

                    clearTimeout(tm);
                }, 2000);
            });
        }

    }
  };

  useEffect(() => {
    if (tournamentId) {
      getTournament();
    }

    return () => {
      setTournament(null);
    };
  }, [tournamentId]);

  const getTournament = async () => {
    try {
      const response = await TournamentService.getByID(tournamentId);
      setTournament(response.data.tournament);
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  const initialValues = useMemo(
    () => ({
      title: tournament?.title ?? '',
      description: tournament?.description ?? '',
      imageURL: tournament?.imageURL ?? '',
      prizes: tournament?.prizes ?? defaultPrizes,
      buyIn: tournament?.buyIn ?? 0,
      places: tournament?.places ?? 0,
      maxPlayers: tournament?.maxPlayers ?? 2,
      botCount: tournament?.botCount ?? 0,
      maxPlayersRestrict: tournament?.maxPlayersRestrict ?? false,
      isPrivate: tournament?.isPrivate ?? false,
      matchCount: tournament?.gameSettings?.matchCount ?? 1,
      fenType: tournament?.gameSettings?.fenType ?? EGameFen.FEN_NORMAL,
      type: tournament?.type ?? ETournamentType.PLAY_OFF,
      fromRank: tournament?.fromRank ?? '',
      startAt: tournament?.startAt ? moment(tournament.startAt).format('YYYY-MM-DDTHH:mm') : '',
      time: tournament?.gameSettings?.time ?? defaultTimeControl.time,
      registrationAt: tournament?.registrationAt
        ? moment(tournament?.registrationAt).format('YYYY-MM-DDTHH:mm')
        : null,
      startBalance: tournament?.startBalance ?? 0,
      startBank: tournament?.gameSettings?.startBank ?? 0,
      steakBonus: tournament?.settings?.steakBonus ?? 5,
      steakBonusStart: tournament?.settings?.steakBonusStart ?? 4,
      betsMap: tournament?.settings?.betsMap ? Object.entries(tournament?.settings?.betsMap).map(([i, value]) => {return {
        minute: i,
        bet: value,
      }}) : [...defaultBetsMap],
      pauses: tournament?.settings?.pauses ? Object.entries(tournament?.settings?.pauses).map(([i, value]) => {return {
        minute: i,
        amount: value,
      }}) : [...defaultPauses],
      duration: tournament?.settings?.duration ?? 60,
      buyInCloseTime: tournament?.settings?.buyInCloseTime ?? 30,
      chipsStartLevel: tournament?.settings?.chipsStartLevel ?? 100,
      betweenSeconds: tournament?.settings?.betweenSeconds ?? 5,
    }),
    [tournament],
  );

  const handleDeleteRow = (array, name, setFieldValue) => {
    setFieldValue(name, [...array.slice(0, -1)]);
  };

  const handleAddBetMapItem = (betsMap, setFieldValue) => {
    const lastBet = betsMap[betsMap.length - 1];
    const newBet = {
      minute: !lastBet ? 0 : (lastBet?.minute || 0) + 15,
      bet: 0,
    };
    setFieldValue('betsMap',[...betsMap, newBet]);
  };

  const handleAddPauseItem = (pauses, setFieldValue) => {
    const newPause = {
      minute: 0,
      amount: 0,
    };
    setFieldValue('pauses',[...pauses, newPause]);
  };

  const handleAddOnePrize = (prizes, setFieldValue) => {
    const lastPrize = prizes[prizes.length - 1];
    const newPrize = {
      amount: 0,
      rating: 0,
      place: lastPrize && (lastPrize?.place === 4 || lastPrize?.place == null) ? null : (lastPrize?.place || 0) + 1,
      range:
      lastPrize?.place === 4
          ? [4, 8]
          : (lastPrize && lastPrize?.place) == null
          ? lastPrize?.range.map((r) => r * 2)
          : null,
    };
    setFieldValue('prizes', [...prizes, newPrize]);
  };

  const [value, setValue] = React.useState(0);

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className={styles.dialog}
        PaperProps={{ className: styles.paper }}
      >
        <DialogTitle id="form-dialog-title">
            <div className={styles.topNav}>
              {isMode(mode, modes.update) ? 'Обновить' : 'Создать'} турнир

              {isMode(mode, modes.update) && <div className={styles.add} onClick={() => setAddingUser(!isAddingUser)}>{isAddingUser ? 'edit tournament' : 'add user'}</div>}
            </div>
        </DialogTitle>
        <DialogContent>
            <div className={isAddingUser ? styles.hidden : undefined}>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              title: Yup.string().required('Введите название'),
              description: Yup.string(),
              buyIn: Yup.number().required('Введите Сумму buyIn'),
              steakBonus: Yup.number().required('Введите Сумму Бонуса за ставку'),
              steakBonusStart: Yup.number().required('Введите начальную сумму бонуса за ставку'),
              botCount: Yup.number().required('Введите кол-во ботов'),
              prizes: Yup.array().of(
                Yup.object().shape({
                  amount: Yup.number('Должно быть числом').required('Введите сумму приза'),
                  place: Yup.number('Должно быть числом').nullable(true),
                  range: Yup.array().of(Yup.number('Must be number')).nullable(true),
                }),
              ),
              matchCount: Yup.number().min(1, 'Минимум - 1').required('Обязательное поле'),
              maxPlayers: Yup.number()
                .min(2, 'Минимальное количество участников -> 2')
                .required('Введите количество игроков')
                .test(
                  'maxPlayers',
                  'Количество игроков должно быть кратно 2',
                  (value) => !!availableValues.find((el) => el === +value),
                ),
              startAt: Yup.date().required('Введите дату турнира'),
              time: Yup.number('Время должно быть числом').required('Введите время игр'),
              buyInCloseTime: Yup.number('Время должно быть числом').required('Введите время игр'),
              duration: Yup.number('Время должно быть числом').required('Введите время игр'),
              chipsStartLevel: Yup.number('Время должно быть числом').required('Введите время игр'),
              betweenSeconds: Yup.number('Время должно быть числом').required('Введите время'),
              type: Yup.string().required('Введите тип турнира'),
              fromRank: Yup.string(),
              registrationStartDate: Yup.date(),
              betsMap:Yup.array().of(
                Yup.object().shape({
                  minute: Yup.number('Должно быть числом').nullable(true),
                  bet: Yup.number('Должно быть числом'),
                }),
              ),
              pauses:Yup.array().of(
                Yup.object().shape({
                  minute: Yup.number('Должно быть числом').required('Введите время паузы'),
                  amount: Yup.number('Должно быть числом').required('Введите длинну паузы'),
                }),
              ),
            })}
            onSubmit={(values) => onSubmit(values, initialValues)}
            validateOnChange
            render={({
              values,
              errors,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              touched,
            }) => {
              return (
                <form onSubmit={handleSubmit} className={styles.form}>
                  <FormControl component="fieldset" className={styles.formControl}>
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.title && touched.title)}
                      name="title"
                      margin="dense"
                      label="Название"
                      value={values.title}
                      placeholder="Введите название"
                    />

                    <div style={{ color: 'red', fontSize: 12 }}>
                      {touched.title ? (errors.title as string) : ''}
                    </div>

                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.description && touched.description)}
                      name="description"
                      margin="dense"
                      label="Описание"
                      value={values.description}
                      placeholder="Введите Описание"
                    />
                    <div style={{ color: 'red', fontSize: 12 }}>
                      {touched.description ? (errors.description as string) : ''}
                    </div>

                    <FormControl className={styles.formControl} size="small">
                      <UploadImageInput
                        image={values.imageURL}
                        onChange={(value) => setFieldValue('imageURL', value)}
                      />
                      <div style={{ color: 'red', fontSize: 12 }}>
                        {touched.imageURL ? (errors.imageURL as string) : ''}
                      </div>
                    </FormControl>

                    <FormControl style={{ minWidth: 120, margin: '10px 0' }} size="small">
                      <InputLabel id="demo-select-small">Время игр</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={values.time}
                        label="Время"
                        name="time"
                        onChange={handleChange}
                      >
                        {timeControlList.map((timeControl) => {
                          return (
                            <MenuItem value={timeControl.time} key={timeControl.time}>
                              {timeControl.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    {/* <FormControl style={{ minWidth: 120, margin: '10px 0' }} size="small">
                      <InputLabel id="demo-select-small">Цвет</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={values.color}
                        label="Цвет"
                        name="color"
                        onChange={handleChange}
                      >
                        {colors.map((color) => (
                          <MenuItem value={color.value} key={color.value}>
                            {color.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
                    <FormControl style={{ minWidth: 120, margin: '10px 0' }} size="small">
                      <InputLabel id="demo-select-small">FEN</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={values.fenType}
                        label="FEN"
                        name="fenType"
                        onChange={handleChange}
                      >
                        <MenuItem value={EGameFen.FEN_NORMAL}>Классический</MenuItem>
                        <MenuItem value={EGameFen.FEN_960}>960</MenuItem>
                      </Select>
                    </FormControl>

                    {isMode(mode, modes.create) && (
                      <FormControl style={{ minWidth: 120, margin: '10px 0' }} size="small">
                        <InputLabel id="demo-select-small">Тип турнира</InputLabel>
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={values.type}
                          label="Тип"
                          name="type"
                          onChange={handleChange}
                        >
                          <MenuItem value={ETournamentType.PLAY_OFF}>PLAY OFF</MenuItem>
                          <MenuItem value={ETournamentType.PLAY_OFF_LONG}>PLAY OFF LONG</MenuItem>
                          <MenuItem value={ETournamentType.LEAGUE}>LEAGUE</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs value={value} onChange={handleChangeTabs} aria-label="tabs" variant="fullWidth">
                        <Tab label="Настройки"  {...a11yProps(0)} />
                        <Tab label="Дополнительно"  {...a11yProps(1)} disabled={values.type !== ETournamentType.LEAGUE}/>
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>

                    <FormControl style={{ minWidth: 120, margin: '10px 0',  width: '100%'  }} size="small">
                      <InputLabel id="demo-select-small">Ранг</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={values.fromRank}
                        label="Ранг"
                        name="fromRank"
                        onChange={handleChange}
                        displayEmpty
                        error={touched.fromRank ? Boolean(errors.fromRank) : false}
                      >
                        {isLoading && (
                          <div className={styles.rankIcon}>
                            <BeatLoader size={16} color="#00acc1" loading={true} />
                          </div>
                        )}
                        {isError && (
                          <div className={styles.rankIcon}>
                            <ErrorIcon className={styles.errorIcon} />
                          </div>
                        )}
                        {ranks &&
                          ranks.map((rank) => (
                            <MenuItem key={rank._id} value={rank._id}>
                              {rank.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>

                    {isMode(mode, modes.create) && (
                      <>
                        <TextField
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!(errors.maxPlayers && touched.maxPlayers)}
                          name="maxPlayers"
                          margin="dense"
                          label="Количество игроков"
                          value={values.maxPlayers}
                          placeholder="Введите количество"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          style={{width: '100%'}}
                        />
                        <div style={{ color: 'red', fontSize: 12 }}>
                          {touched.maxPlayers ? (errors.maxPlayers as string) : ''}
                        </div>
                      </>
                    )}

                      <TextField
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!(errors.botCount && touched.botCount)}
                          name="botCount"
                          margin="dense"
                          label="Bot count"
                          value={values.botCount}
                          placeholder="Введите кол-во ботов"
                          InputProps={{
                              inputComponent: NumberFormatCustom,
                          }}
                          style={{width: '100%'}}
                      />
                      <div style={{ color: 'red', fontSize: 12 }}>
                          {touched.botCount ? (errors.botCount as string) : ''}
                      </div>

                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.buyIn && touched.buyIn)}
                      name="buyIn"
                      margin="dense"
                      label="Buy in"
                      value={values.buyIn}
                      placeholder="Введите buy in"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      style={{width: '100%'}}
                    />
                    <div style={{ color: 'red', fontSize: 12 }}>
                      {touched.buyIn ? (errors.buyIn as string) : ''}
                    </div>

                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.places && touched.places)}
                      name="places"
                      margin="dense"
                      label="Количество призёров для TBA"
                      value={values.places}
                      placeholder="Количество призёров для TBA"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      style={{width: '100%'}}
                    />
                    <div style={{ color: 'red', fontSize: 12 }}>
                      {touched.places ? (errors.places as string) : ''}
                    </div>

                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.matchCount && touched.matchCount)}
                      name="matchCount"
                      margin="dense"
                      label="Серия игр"
                      value={values.matchCount}
                      placeholder="Серия игр"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      style={{width: '100%'}}
                    />

                    <div style={{ color: 'red', fontSize: 12 }}>
                      {(errors.matchCount as string) || ''}
                    </div>

                    {values.type === ETournamentType.LEAGUE && (
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.startBalance && touched.startBalance)}
                        name="startBalance"
                        margin="dense"
                        label="Стартовый баланс пользователей"
                        value={values.startBalance}
                        placeholder="Стартовый баланс пользователей"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        style={{width: '100%'}}
                      />
                    )}

                    {values.type === ETournamentType.LEAGUE && (
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.startBank && touched.startBank)}
                        name="startBank"
                        margin="dense"
                        label="Стартовый банк игр"
                        value={values.startBank}
                        placeholder="Стартовый банк игр"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        style={{width: '100%'}}
                      />
                    )}

                    <div
                      style={{
                        border: '3px solid gray',
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '15px 0',
                        padding: '10px 5px',
                      }}
                    >
                      {values.prizes.map((prize, i) => {
                        return (
                          <div
                            style={{
                              display: 'grid',
                              gridTemplateColumns: '1fr 1fr',
                              gridColumnGap: '5px',
                            }}
                          >
                            <TextField
                              key={i}
                              onChange={handleChange}
                              name={`prizes[${i}].amount`}
                              margin="dense"
                              label={`Приз за ${
                                prize.place || `${prize.range[0]}-${prize.range[1]}`
                              } место`}
                              value={values.prizes[i].amount}
                              placeholder="Введите приз"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                            />
                            <TextField
                              key={i}
                              onChange={handleChange}
                              name={`prizes[${i}].rating`}
                              margin="dense"
                              label={`Рейтинг за ${
                                prize.place || `${prize.range[0]}-${prize.range[1]}`
                              } место`}
                              value={values.prizes[i].rating}
                              placeholder="Введите рейтинг"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                            />
                          </div>
                        );
                      })}
                      <div style={{ color: 'red', fontSize: 12 }}>
                        {Array.isArray(errors.prizes) &&
                          errors.prizes.filter(Boolean).map((err: Record<string, any>, i) => {
                            return <span key={i}>{err.amount || err.place}</span>;
                          })}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => {
                            handleAddOnePrize(values.prizes, setFieldValue);
                          }}
                        >
                          <AddIcon />
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => {
                            handleDeleteRow(values.prizes, 'prizes',setFieldValue);
                          }}
                        >
                          <RemoveIcon />
                        </Button>
                      </div>
                    </div>
                    <FormControlLabel
                      style={{width: '100%'}}
                      control={
                        <Checkbox
                          checked={Boolean(values.maxPlayersRestrict)}
                          name="maxPlayersRestrict"
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={
                        <span className={styles.formControlLabel}>
                          Ограничение по кол. игроков?
                        </span>
                      }
                    />
                    <FormControlLabel
                      style={{width: '100%'}}
                      control={
                        <Checkbox
                          checked={Boolean(values.isPrivate)}
                          name="isPrivate"
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={
                        <span className={styles.formControlLabel}>
                            Приватный турнир
                        </span>
                      }
                    />
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.startAt && touched.startAt)}
                      name="startAt"
                      type="datetime-local"
                      margin="dense"
                      value={values.startAt}
                      label="Дата начала турнира"
                      placeholder="Дата турнира"
                      InputLabelProps={{ shrink: true }}
                      style={{width: '100%'}}
                    />
                    <ErrorMessage message={touched.startAt ? errors.startAt : ''} />
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="registrationAt"
                      type="datetime-local"
                      margin="dense"
                      value={values.registrationAt}
                      label="Дата начала регистрации"
                      placeholder="Дата начала регистрации"
                      InputLabelProps={{ shrink: true }}
                      style={{width: '100%'}}
                    />
                    <ErrorMessage message={touched.registrationAt ? errors.registrationAt : ''} />
                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(values.settings?.isPrivate)}
                          name="settings.isPrivate"
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={<span className={styles.formControlLabel}>Приватный?</span>}
                    />
                    {values.settings?.isPrivate && (
                      <>
                        <TextField
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // @ts-ignore
                          error={!!(errors.settings?.password && touched.settings?.password)}
                          name="settings.password"
                          margin="dense"
                          label="Пароль"
                          value={values.settings?.password}
                          placeholder="Введите пароль"
                        />
                        <ErrorMessage
                          // @ts-ignore
                          message={touched.settings?.password ? errors.settings?.password : ''}
                        />
                      </>
                    )} */}
                      <FormControl style={{ minWidth: 120, width: '100%' }} size="small">
                        <InputLabel id="demo-select-small">Шаблон турнира</InputLabel>
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          label="Шаблон"
                          name="templateId"
                          onChange={(event) => {
                            const value = event.target.value as string;
                            const template = tournamentTemplates.find((t) => t._id === value);
                            if (!template) {
                              return;
                            }
                            setFieldValue('title', template.title);
                            setFieldValue('description', template.description);
                            setFieldValue('imageURL', template.imageURL);
                            setFieldValue('prizes', template.prizes);
                            setFieldValue('matchCount', template.gameSettings?.matchCount ?? 1);
                            setFieldValue('maxPlayers', template.maxPlayers);
                            setFieldValue('time', template.gameSettings.time);
                            setFieldValue('type', template.type);
                            setFieldValue('maxPlayersRestrict', template.maxPlayersRestrict)
                            setFieldValue("places", template.places ?? 0)
                          }}
                        >
                          {tournamentTemplates.map((template) => (
                            <MenuItem value={template._id}>{template.title}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.steakBonus && touched.steakBonus)}
                        name="steakBonus"
                        margin="dense"
                        label="Бонус за ставку"
                        value={values.steakBonus}
                        placeholder="Введите количество"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        style={{width: '100%'}}
                      />
                      <div style={{ color: 'red', fontSize: 12 }}>
                        {touched.steakBonus ? (errors.steakBonus as string) : ''}
                      </div>
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.steakBonusStart && touched.steakBonusStart)}
                        name="steakBonus"
                        margin="dense"
                        label="Начальный бонус за ставку"
                        value={values.steakBonusStart}
                        placeholder="Введите количество"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        style={{width: '100%'}}
                      />
                      <div style={{ color: 'red', fontSize: 12 }}>
                        {touched.steakBonusStart ? (errors.steakBonusStart as string) : ''}
                      </div>
                      <div
                        style={{
                          border: '3px solid gray',
                          display: 'flex',
                          flexDirection: 'column',
                          margin: '15px 0',
                          padding: '10px 5px',
                        }}
                      >
                        <div style={{ width: '100%', textAlign: 'center', paddingBottom: '20px'}}>Карта ставок</div>
                        {values.betsMap.map((item, i) => {
                          return (
                            <div
                              style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                gridColumnGap: '5px',
                              }}
                            >
                              <TextField
                                key={`betsMap[${i}].minute`}
                                onChange={handleChange}
                                name={`betsMap[${i}].minute`}
                                margin="dense"
                                label={`Минута`}
                                value={values.betsMap[i].minute}
                                placeholder="Введите минуту"
                                contentEditable={false}
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                              />
                              <TextField
                                key={`betsMap[${i}].bet`}
                                onChange={handleChange}
                                name={`betsMap[${i}].bet`}
                                margin="dense"
                                label={`Ставка`}
                                value={values.betsMap[i].bet}
                                placeholder="Введите ставку"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                              />
                            </div>
                          );
                        })}
                        <div style={{ color: 'red', fontSize: 12 }}>
                          {Array.isArray(errors.betsMap) &&
                            errors.betsMap.filter(Boolean).map((err: Record<string, any>, i) => {
                              return <span key={i}>{err.minute || err.bet}</span>;
                            })}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={() => {
                              handleAddBetMapItem(values.betsMap, setFieldValue);
                            }}
                          >
                            <AddIcon />
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => {
                              handleDeleteRow(values.betsMap, 'betsMap',setFieldValue);
                            }}
                          >
                            <RemoveIcon />
                          </Button>
                        </div>
                      </div>
                      <div
                        style={{
                          border: '3px solid gray',
                          display: 'flex',
                          flexDirection: 'column',
                          margin: '15px 0',
                          padding: '10px 5px',
                        }}
                      >
                        <div style={{ width: '100%', textAlign: 'center', paddingBottom: '20px'}}>Паузы</div>
                        {values.pauses.map((item, i) => {
                          return (
                            <div
                              style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                gridColumnGap: '5px',
                              }}
                            >
                              <TextField
                                key={`pauses[${i}].minute`}
                                onChange={handleChange}
                                name={`pauses[${i}].minute`}
                                margin="dense"
                                label={`Минута ${item.minute}`}
                                value={values.pauses[i].minute}
                                placeholder="Введите минуту"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                              />
                              <TextField
                                key={`pauses[${i}].amount`}
                                onChange={handleChange}
                                name={`pauses[${i}].amount`}
                                margin="dense"
                                label={`Продолжительность`}
                                value={values.pauses[i].amount}
                                placeholder="Введите продолжительность"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                              />
                            </div>
                          );
                        })}
                        <div style={{ color: 'red', fontSize: 12 }}>
                          {Array.isArray(errors.pauses) &&
                            errors.pauses.filter(Boolean).map((err: Record<string, any>, i) => {
                              return <span key={i}>{err.minute || err.amount}</span>;
                            })}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={() => {
                              handleAddPauseItem(values.pauses, setFieldValue);
                            }}
                          >
                            <AddIcon />
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => {
                              handleDeleteRow(values.pauses, 'pauses',setFieldValue);
                            }}
                          >
                            <RemoveIcon />
                          </Button>
                        </div>
                      </div>
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.betweenSeconds && touched.betweenSeconds)}
                        name="betweenSeconds"
                        margin="dense"
                        label="Время между (сек)"
                        value={values.betweenSeconds}
                        placeholder="Введите время"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        style={{width: '100%'}}
                      />
                      <div style={{ color: 'red', fontSize: 12 }}>
                        {touched.betweenSeconds ? (errors.betweenSeconds as string) : ''}
                      </div>
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.buyInCloseTime && touched.buyInCloseTime)}
                        name="buyInCloseTime"
                        margin="dense"
                        label="Время до закрытия BuyIn"
                        value={values.buyInCloseTime}
                        placeholder="Введите время"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        style={{width: '100%'}}
                      />
                      <div style={{ color: 'red', fontSize: 12 }}>
                        {touched.buyInCloseTime ? (errors.buyInCloseTime as string) : ''}
                      </div>
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.duration && touched.duration)}
                        name="duration"
                        margin="dense"
                        label="Продолжительность"
                        value={values.duration}
                        placeholder="Введите время"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        style={{width: '100%'}}
                      />
                      <div style={{ color: 'red', fontSize: 12 }}>
                        {touched.duration ? (errors.duration as string) : ''}
                      </div>
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.chipsStartLevel && touched.chipsStartLevel)}
                        name="chipsStartLevel"
                        margin="dense"
                        label="Начальная ставка"
                        value={values.chipsStartLevel}
                        placeholder="Введите количество"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        style={{width: '100%'}}
                      />
                      <div style={{ color: 'red', fontSize: 12 }}>
                        {touched.chipsStartLevel ? (errors.chipsStartLevel as string) : ''}
                      </div>
                    </CustomTabPanel>
                    </FormControl>
                  <DialogActions>
                    <Button color="secondary" variant="contained" onClick={() => handleClose()}>
                      Отмена
                    </Button>
                    <Button color="primary" variant="contained" onClick={() => handleSubmit()}>
                      {isMode(mode, modes.update) ? 'Обновить' : 'Создать'}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
            </div>
            <div className={!isAddingUser ? styles.hidden : undefined}>
                {!mes.length && !loading && <form onSubmit={sendForm} className={styles.userForm}>
                    <input ref={inputRef} placeholder="User name or user email" className={styles.userFormInput} type="text"/>
                    <button type="submit">Add user</button>
                </form>}
                {loading ? '...' : ''}
                {mes.length && !loading ? mes : ''}
            </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateOrUpdateTournamentModal;
