import React, { useRef, useState } from 'react';

import moment from 'moment-timezone';

import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';

import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import endpoints from 'api/endpoints';
import { useAuthAPI } from 'hooks/use-auth-api';
import { useHistory } from 'react-router-dom';
import { errorService } from 'services/alert/services';
import { shortenString } from '../../utils/shortenString';
import CreateChatModal from './create-chat-modal';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: 300,
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

const MessageType = {
  TEXT: 'text',
  IMAGES_WITH_CAPTION: 'images_with_caption',
};

const renderLastMessage = (type, content) => {
  switch (type) {
    case MessageType.TEXT:
      return shortenString(content, 70);
    case MessageType.IMAGES_WITH_CAPTION:
      return 'Изображение';
    default:
      return shortenString(content, 70);
  }
};

const MIN_PAGE_SIZE = 30;
const pageSizes = [30, 50, 100];

const useStyles = makeStyles(styles);

export default function Support() {
  const classes = useStyles();
  const request = useAuthAPI();
  const tableRef = useRef();

  const [isCreateModalOpen, setCreateModalOpen] = useState(false);

  const history = useHistory();

  const onData = async (query) => {
    try {
      const response = await request(`${endpoints.supportChats}`);
      return {
        data: response.data.chats,
        page: query.page,
        totalCount: response.data.chats.length,
        pageSize: query.pageSize,
      };
    } catch (e) {
      errorService.sendError(e);
    }
  };

  const onRowClick = (_, rowData) => {
    history.push({ pathname: `/admin/chat/${rowData._id}` });
  };

  const onCreateChatModalClose = () => {
    setCreateModalOpen(false);
  };

  return (
    <div>
      <CreateChatModal
        open={isCreateModalOpen}
        onHandleClose={onCreateChatModalClose}
        onSuccess={async (id) => {
          // @ts-ignore
          if (tableRef.current) tableRef.current.retry();
          onCreateChatModalClose();
          history.push(`/admin/chat/${id}`);
        }}
      />
      <GridContainer>
        {/* @ts-ignore */}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Саппорт</p>
            </CardHeader>
            <CardBody>
            {/* @ts-ignore */}
              <MaterialTable
                title="Чаты"
                onRowClick={onRowClick}
                tableRef={tableRef}
                localization={{
                  body: {
                    editTooltip: 'Редактировать',
                    deleteTooltip: 'Удалить',
                  },
                  header: {
                    actions: 'Действия',
                  },
                }}
                actions={[
                  {
                    icon: 'add',
                    tooltip: 'Создать чат',
                    isFreeAction: true,
                    onClick: () => setCreateModalOpen(true),
                  },
                ]}
                columns={[
                  {
                    title: 'ID',
                    field: '_id',
                    sorting: false,
                  },
                  {
                    title: 'Последнее сообщение',
                    field: 'lastMessage',
                    editable: false,
                    render: (rowData) =>
                      shortenString(rowData.lastMessage, 70),
                  },
                  {
                    title: 'Дата последнего сообщ. юзера',
                    field: 'lastMessageAt',
                    type: 'date',
                    editable: false,
                    render: (rowData) =>
                      moment
                        .tz(rowData.lastMessageAt, moment.tz.guess())
                        .format('HH:mm DD-MM-YYYY'),
                  },
                  {
                    title: 'Пользователь',
                    render: (rowData) => (
                      <>
                        {rowData.user.email}
                        <br />
                        {rowData.user.nickname}
                      </>
                    ),
                  },
                ]}
                data={onData}
                options={{
                  filtering: false,
                  pageSizeOptions: pageSizes,
                  pageSize: MIN_PAGE_SIZE,
                }}
                editable={{
                  isEditable: () => true,
                  onRowAddCancelled: () => console.log('Row adding cancelled'),
                  onRowUpdateCancelled: () => console.log('Row editing cancelled'),
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
