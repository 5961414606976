import React, { memo, useState, useMemo, useCallback } from 'react';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import cn from 'classnames';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Select, MenuItem } from '@material-ui/core';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Autocomplete, Box, Chip, Divider, Paper } from '@mui/material';
import * as _ from 'lodash';

import { errorService } from 'services/alert/services';
import { useStore } from 'hooks/use-store';
import { mapExternalGameProviderType, mapExternalGameType } from 'domain/external-game';
import {
  EExternalGameProviderType,
  EExternalGameType,
} from 'domain/external-game/external-game.types';
import { useStores } from 'hooks/use-stores';

import UploadImage from '../Banners/components/UploadImage';
import { FileService } from '../../services/api/file';

import { dialogStyles } from './styles';

const validationSchema = Yup.object().shape({
  externalId: Yup.string().required('Введите ID'),
});

const CreateOrUpdateExternalGameModal = ({
  open,
  handleClose,
  mode,
  externalGame,
  onCreate,
  onUpdate,
}) => {
  const styles = dialogStyles();
  const { externalGameProviders } = useStore();
  const { countryStore } = useStores();
  const [selectAll, setSelectAll] = useState(false);

  const handleSubmit = useCallback(
    async (values, actions: FormikHelpers<Record<string, any>>) => {
      try {
        if (mode === 'create') await onCreate(values);
        else await onUpdate(externalGame._id, values);
      } catch (e) {
        errorService.sendError(e.message);
      } finally {
        actions.setSubmitting(false);
        handleClose();
      }
    },
    [mode, handleClose, onCreate, onUpdate],
  );

  const onUpload = async (files, setFieldValue) => {
    try {
      const formData = new FormData();
      Array.from(files).forEach((file) => {
        formData.append('files', file as Blob);
      });
      const { data } = await FileService.uploadFiles(formData);
      setFieldValue('imageUrl', data.url);
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  const initialValues = useMemo(() => {
    if (mode === 'create') {
      return {
        externalId: '',
        imageUrl: '',
        parentProvider: EExternalGameProviderType.ALGNET,
        type: EExternalGameType.SLOTS,
        name: '',
        extra: {
          gameType: '',
        },
        enabled: false,
        isNew: true,
        isPopular: false,
        order: 1,
        providerId: '',
        onlyHorizontal: false,
        supportedCountries: [],
        generalMin: null,
        generalMax: null,
        isNewOrder: 0,
      };
    }
    return {
      externalId: externalGame.externalId,
      imageUrl: externalGame.imageUrl,
      parentProvider: externalGame.parentProvider,
      type: externalGame.type,
      name: externalGame.name ?? '',
      enabled: externalGame.enabled,
      order: externalGame.order,
      isNew: externalGame.isNew,
      isPopular: externalGame.isPopular,
      providerId: externalGame.providerId,
      extra: externalGame.extra,
      onlyHorizontal: externalGame.onlyHorizontal,
      supportedCountries: externalGame.supportedCountries ?? [],
      generalMin: externalGame.generalMin,
      generalMax: externalGame.generalMax,
      isNewOrder: externalGame.orderOptions?.isNew ?? 0,
    };
  }, [mode, externalGame]);

  const countriesByIds = useMemo(() => {
    return _.keyBy(countryStore.countries, 'id');
  }, [countryStore.countries]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className={styles.dialog}
        PaperProps={{ className: styles.paper }}
      >
        <DialogTitle>{mode === 'create' ? 'Создать' : 'Обновить'} игру</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            validateOnChange
            onSubmit={handleSubmit}
            render={({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              touched,
              values,
              errors,
              isSubmitting,
            }) => {
              return (
                <form onSubmit={handleSubmit} className={styles.form}>
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.gameId && touched.gameId)}
                      name="externalId"
                      margin="dense"
                      label="ID"
                      value={values.externalId}
                      placeholder="Введите ID"
                    />
                    <div className={styles.errorText}>
                      {touched.gameId ? (errors.gameId as string) : ''}
                    </div>
                  </FormControl>
                  <FormControl className={styles.formControl} size="small">
                    <UploadImage
                      onUpload={(files) => onUpload(files, setFieldValue)}
                      image={values.imageUrl}
                      onImageRemove={() => setFieldValue('imageUrl', '')}
                    />
                    <div className={styles.errorText}>
                      {touched.image ? (errors.image as string) : ''}
                    </div>
                  </FormControl>
                  <FormControl
                    className={cn(styles.formControl, styles.selectFormControl)}
                    size="small"
                  >
                    <Select
                      value={values.parentProvider}
                      label="Провайдер"
                      name="parentProvider"
                      onChange={handleChange}
                      displayEmpty
                    >
                      {Object.entries(mapExternalGameProviderType).map(([value, label]) => (
                        <MenuItem key={value} value={+value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    className={cn(styles.formControl, styles.selectFormControl)}
                    size="small"
                  >
                    <Select
                      value={values.type}
                      label="Тип игры"
                      name="type"
                      onChange={handleChange}
                      displayEmpty
                    >
                      {Object.entries(mapExternalGameType).map(([value, label]) => (
                        <MenuItem key={value} value={+value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.name && touched.name)}
                      name="name"
                      margin="dense"
                      label="Название(обязательное поле)"
                      value={values.name}
                      placeholder="Введите название"
                    />
                    <div className={styles.errorText}>
                      {touched.name ? (errors.name as string) : ''}
                    </div>
                  </FormControl>
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // @ts-ignore
                      error={!!(errors?.extra?.gameType && touched?.extra?.gameType)}
                      name="extra.gameType"
                      margin="dense"
                      label="Тип игры"
                      value={values?.extra?.gameType}
                      placeholder="Введите gameType"
                    />
                    <div className={styles.errorText}>
                      {touched.gameType ? (errors.gameType as string) : ''}
                    </div>
                  </FormControl>
                  <FormControl
                    className={cn(styles.formControl, styles.selectFormControl)}
                    size="small"
                  >
                    <Select
                      value={values.providerId}
                      label="Провайдер игры"
                      name="providerId"
                      onChange={handleChange}
                      displayEmpty
                    >
                      {externalGameProviders.map((provider) => (
                        <MenuItem key={provider._id} value={provider._id}>
                          {provider.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.order && touched.order)}
                      name="order"
                      margin="dense"
                      label="Порядок игры"
                      value={values.order}
                      placeholder="Порядок игры"
                      type="number"
                    />
                    <div className={styles.errorText}>
                      {touched.order ? (errors.order as string) : ''}
                    </div>
                  </FormControl>
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.isNewOrder && touched.isNewOrder)}
                      name="isNewOrder"
                      margin="dense"
                      label="Порядок игры в секции Новые"
                      value={values.isNewOrder}
                      placeholder="Порядок игры"
                      type="number"
                    />
                    <div className={styles.errorText}>
                      {touched.isNewOrder ? (errors.isNewOrder as string) : ''}
                    </div>
                  </FormControl>
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.generalMin && touched.generalMin)}
                      name="generalMin"
                      margin="dense"
                      label="Мин. ставка"
                      value={values.generalMin}
                      placeholder="Мин. ставка"
                      type="number"
                    />
                    <div className={styles.errorText}>
                      {touched.generalMin ? (errors.generalMin as string) : ''}
                    </div>
                  </FormControl>
                  <FormControl className={styles.formControl} size="small">
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.generalMax && touched.generalMax)}
                      name="generalMax"
                      margin="dense"
                      label="Макс. ставка"
                      value={values.generalMax}
                      placeholder="Макс. ставка"
                      type="number"
                    />
                    <div className={styles.errorText}>
                      {touched.generalMax ? (errors.generalMax as string) : ''}
                    </div>
                  </FormControl>
                  <FormControl
                    className={cn(styles.formControl, styles.checkboxFormControl)}
                    size="small"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(values.enabled)}
                          name="enabled"
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={<span className={styles.formControlLabel}>Включено?</span>}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(values.isNew)}
                          name="isNew"
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={<span className={styles.formControlLabel}>Новая?</span>}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(values.isPopular)}
                          name="isPopular"
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={<span className={styles.formControlLabel}>Популярная?</span>}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(values.onlyHorizontal)}
                          name="onlyHorizontal"
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={<span className={styles.formControlLabel}>Только горизонтально?</span>}
                    />
                    <Autocomplete
                      multiple
                      id="tags-filled"
                      options={countryStore.countries?.map((option) => option.id)}
                      value={values.supportedCountries}
                      onChange={(e, newValues) => {
                        setFieldValue('supportedCountries', newValues);
                      }}
                      getOptionLabel={(option) => {
                        return countriesByIds[option]?.name;
                      }}
                      filterSelectedOptions
                      renderTags={(value: readonly string[], getTagProps) => {
                        const numTags = value.length;
                        const limitTags = 10;
                        return (
                          <>
                            {value.slice(0, 5).map((option: string, index: number) => {
                              const { key, ...tagProps } = getTagProps({ index });
                              return (
                                <Chip
                                  variant="outlined"
                                  label={countriesByIds[option]?.name}
                                  key={key}
                                  {...tagProps}
                                />
                              );
                            })}
                            {numTags > limitTags && ` +${numTags - limitTags}`}
                          </>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Страны"
                          placeholder="Страны"
                        />
                      )}
                      PaperComponent={(paperProps) => {
                        const { children, ...restPaperProps } = paperProps;
                        return (
                          <Paper {...restPaperProps}>
                            <Box onMouseDown={(e) => e.preventDefault()} pl={1.5} py={0.5}>
                              <FormControlLabel
                                onClick={(e) => {
                                  e.preventDefault();
                                  setSelectAll((prev) => {
                                    if (!prev)
                                      setFieldValue(
                                        'supportedCountries',
                                        countryStore.countries.map((c) => c.id),
                                      );
                                    else setFieldValue('supportedCountries', []);
                                    return !prev;
                                  });
                                }}
                                label="Выбрать все"
                                control={<Checkbox id="select-all-checkbox" checked={selectAll} />}
                              />
                            </Box>
                            <Divider />
                            {children}
                          </Paper>
                        );
                      }}
                    />
                  </FormControl>
                  <DialogActions>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {mode === 'create' ? 'Создать' : 'Обновить'}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default memo(CreateOrUpdateExternalGameModal);
