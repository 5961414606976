import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import { Select, InputLabel, MenuItem } from '@material-ui/core';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { dialogStyles } from './styles';
import { isMode, modes } from './utils';
import { useStore } from 'hooks/use-store';
import { TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

export const staticYearMonthDay = "2022-12-18"

const CreateOrUpdateScheduleModal = ({ open, handleClose, onSubmit, mode, schedule }) => {
  const styles = dialogStyles();
  const {tournamentTemplates} = useStore()

  const initialValues = useMemo(
    () => ({
      title: schedule?.title ?? '',
      templateId: schedule?.templateId ?? null,
      startAt: schedule?.startAt ?? 0,
      weekDays: schedule?.weekDays ?? [0],
      isActive: schedule?.isActive ?? true,
    }),
    [schedule],
  );

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className={styles.dialog}
        PaperProps={{ className: styles.paper }}
      >
        <DialogTitle id="form-dialog-title">
          {isMode(mode, modes.update) ? 'Обновить' : 'Создать'} планировщик
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              title: Yup.string().required('Введите название'),
              startAt: Yup.number().required('Выберите время запуска'),
              templateId: Yup.string().required('Выберите шаблон турнира'),
            })}
            onSubmit={(values) => onSubmit(values, initialValues)}
            validateOnChange
            render={({ values, errors, handleSubmit, handleChange, handleBlur, touched, setFieldValue }) => {
              return (
                <form onSubmit={handleSubmit} className={styles.form}>
                  <FormControl component="fieldset" className={styles.formControl}>
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.title && touched.title)}
                      name="title"
                      margin="dense"
                      label="Название"
                      value={values.title}
                      placeholder="Введите название"
                    />
                    <div style={{ color: 'red', fontSize: 12 }}>
                      {touched.title ? (errors.title as string) : ''}
                    </div>
                  </FormControl>

                  <FormControl style={{ minWidth: 120, width: "100%", margin: '10px 0 10px' }} size="small">
                    <TimePicker 
                      label="Время запуска" 
                      value={dayjs(staticYearMonthDay).startOf("day").add(values.startAt, "seconds")}
                      onChange={(newValue) => {
                        const startDay = dayjs(newValue).startOf("day")
                        const difference = dayjs(newValue).diff(startDay, "seconds")
                        setFieldValue("startAt", difference)
                      }} 
                    />
                    <div style={{ color: 'red', fontSize: 12 }}>
                      {touched.startAt ? (errors.startAt as string) : ''}
                    </div>
                  </FormControl>

                  <FormControl style={{ minWidth: 120, width: "100%" }} size="small">
                    <InputLabel id="demo-select-small">Шаблон турнира</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={values.templateId}
                      label="Шаблон"
                      name="templateId"
                      onChange={handleChange}
                    >
                      {tournamentTemplates.map(template => (
                        <MenuItem value={template._id}>{template.title}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl style={{ minWidth: 120, width: "100%", margin: '10px 0' }} size="small">
                    <InputLabel id="demo-select-small">Дни недели запуска:</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={values.weekDays}
                      label="Шаблон"
                      name="weekDays"
                      multiple
                      onChange={(e) => {
                        setFieldValue("weekDays", typeof e.target.value === "string" ? e.target.value.split(",") : e.target.value)
                      }}
                    >
                      <MenuItem value={0}>Понедельник</MenuItem>
                      <MenuItem value={1}>Вторник</MenuItem>
                      <MenuItem value={2}>Среда</MenuItem>
                      <MenuItem value={3}>Четверг</MenuItem>
                      <MenuItem value={4}>Пятница</MenuItem>
                      <MenuItem value={5}>Суббота</MenuItem>
                      <MenuItem value={6}>Воскресенье</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(values?.isActive)}
                        name="isActive"
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label={<span className={styles.formControlLabel}>Включен?</span>}
                  />

                  <DialogActions>
                    <Button color="secondary" variant="contained" onClick={() => handleClose()}>
                      Отмена
                    </Button>
                    <Button color="primary" variant="contained" onClick={() => handleSubmit()}>
                      {isMode(mode, modes.update) ? 'Обновить' : 'Создать'}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateOrUpdateScheduleModal;
