import endpoints from 'api/endpoints';
import { api } from 'api/rest';

export class FileService {
    static async uploadFiles(formData) {
        const response = await api.post(endpoints.files, formData);
        return response;
    }

    static async uploadFile(file) {
        const formData = new FormData()
        formData.append("files", file)
        const response = await api.post(endpoints.files, formData);
        return response;
    }

    static async uploadSystemAvatar(formData) {
        const response = await api.post(`${endpoints.files}/system-avatar`, formData);
        return response;
    }
}