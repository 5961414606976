import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_FullScreenToggleButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import qs from 'qs';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import AddIcon from '@mui/icons-material/Add';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { IconButton } from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { useAuthAPI } from 'hooks/use-auth-api';
import endpoints from 'api/endpoints';
import { URLHelper } from 'shared/helpers/URLHelper';
import { useDebounce } from 'hooks/use-debounce';

import { BALANCE_TYPE_MESSAGES } from '../../types/enums/index';

import CreateTransactionDialog from './create-transaction-dialog';
import ImportJackStatsDialog from './import-jack-stats-dialog';
import { TransactionsStatsByDays } from './transactions-stats-by-days';

const styles = {
  increaseBalance: {
    color: '#2eb92e',
  },
  decreaseBalance: {
    color: '#e01919',
  },
  takingReservedBalance: {
    color: '#707070',
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  balance: {
    marginRight: '10px',
    fontWeight: 'bold',
  },
};
// @ts-ignore
const useStyles = makeStyles(styles);

export enum ETransactionType {
  GAME_BET = 1,
  GAME_WIN = 2,
  DIRECT_CHANGE = 3,
}

const transformType = [
  {
    text: 'Game Bet',
    value: '1',
  },
  {
    text: 'Game Win',
    value: '2',
  },
  {
    text: 'Direct Change',
    value: '3',
  },
  {
    text: 'EvenBet Credit',
    value: '4',
  },
  {
    text: 'EvenBet Debit',
    value: '5',
  },
  {
    text: 'EvenBet Rollback',
    value: '6',
  },
  {
    text: 'Jackpoker Get Cash',
    value: '7',
  },
  {
    text: 'Jackpoker Return Cash',
    value: '8',
  },
  {
    text: 'Algnet Credit',
    value: '9',
  },
  {
    text: 'Algnet Debit',
    value: '10',
  },
  {
    text: 'Withdrawal Credit',
    value: '11',
  },
  {
    text: 'Cypix Credit',
    value: '12',
  },
  {
    text: 'Cypix Debit',
    value: '13',
  },
  {
    text: 'Cassy Debit',
    value: '14',
  },
  {
    text: 'XProcessing Credit',
    value: '15',
  },
  {
    text: 'XProcessing Debit',
    value: '16',
  },
  {
    text: 'Paycos Credit',
    value: '17',
  },
  {
    text: 'Paycos Debit',
    value: '18',
  },
  {
    text: 'Withdrawal Rejected',
    value: '19',
  },
  {
    text: 'Deposit',
    value: '20',
  },
  {
    text: 'Withdrawal',
    value: '21',
  },
  {
    text: 'Invite Cancel',
    value: '22',
  },
  {
    text: 'Rank Bonus',
    value: '23',
  },
  {
    text: 'Rake Back',
    value: '24',
  },
  {
    text: 'Rake Back Minus',
    value: '25',
  },
  {
    text: 'Withdrawal Rejected Return Balance',
    value: '26',
  },
  {
    text: 'Debit',
    value: '27',
  },
  {
    text: 'Credit',
    value: '28',
  },
  {
    text: 'Rake',
    value: '29',
  },
  {
    text: 'Payllion Debit',
    value: '30',
  },
];

const transformTypeConverter = transformType.reduce((acc, item) => {
  acc[item.value] = item.text
  return acc;
}, {})

export enum ETransactionTarget {
  REAL = 1,
  COINS = 2,
  PLAY = 3,
  REFERRAL_BONUS = 4,
  REFERRAL_REVENUE_SHARE = 5,
  RAKE_BACK = 6,
}
const balanceTypeLookup = [
  {
    text: 'Cash',
    value: '1',
  },
  {
    text: 'Coins',
    value: '2',
  },
  {
    text: 'Play',
    value: '3',
  },
  {
    text: 'Bonus',
    value: '4',
  },
  {
    text: 'Referral revenue share',
    value: '5',
  },
  {
    text: 'Rakeback',
    value: '6',
  },
];

const externalProviderType = {
  1: 'Dicechess',
  2: 'AlgNet',
  3: 'Evenbet',
};

export const convertIntoArray = (values) => {
  const convertedArray = Object.entries(values).map(([key, value]: [string, string]) => ({
    text: value.replace(/([A-Z])/g, ' $1').trim(),
    value: key,
  }));
  return convertedArray;
};

const validateFilter = (filter) => {
  if (Array.isArray(filter) && !filter[0] && !filter[1]) {
    return false;
  }
  return true;
};

export enum ETransactionProvider {
  DICECHESS = 1,
  ALGNET = 2,
  EVENBET = 3,
}

export const getFilters = (columnFilters) => {
  return columnFilters.reduce((acc, item) => {
    if (validateFilter(item.value)) acc[item.id] = item.value;
    return acc;
  }, {});
};

const MIN_PAGE_SIZE = 30;

const Transactions = () => {
  const params = useParams();

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openImportStatsDialog, setOpenImportStatsDialog] = useState(false);

  const { userId } = params;
  const location = useLocation();
  const user = location.state;

  const searchQueryFilters = qs.parse(location.search.substring(1));

  const columns = useMemo(
    () => [
      {
        header: 'Пользователь',
        accessorKey: 'user',
        Cell: ({ cell }) => {
          return cell.getValue()?.email;
        },
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        header: 'Дата',
        accessorFn: (row) => new Date(row.dateAt),
        accessorKey: 'dateAt',
        filterVariant: 'equals',
        sortingFn: 'datetime',
        Cell: ({ cell }) => moment.utc(cell.getValue()).format('HH:mm:ss DD-MM-YYYY'),
        type: 'date',
        muiTableHeadCellFilterTextFieldProps: { InputProps: { inputProps: { type: 'date' } } },
      },
      {
        header: 'Тип',
        accessorKey: 'type',
        filterVariant: 'multi-select',
        filterSelectOptions: transformType,
        Cell: ({ cell }) => <div>{transformTypeConverter[cell.getValue()] ?? cell.getValue()}</div>,
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        header: 'Тип баланса',
        accessorKey: 'target',
        filterVariant: 'multi-select',
        filterSelectOptions: balanceTypeLookup,
        Cell: ({ cell }) => <div>{BALANCE_TYPE_MESSAGES[cell.getValue()] || cell.getValue()}</div>,
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        header: 'Провайдер',
        accessorKey: 'provider',
        filterVariant: 'multi-select',
        filterSelectOptions: convertIntoArray(externalProviderType),
        Cell: ({ cell }) => <div>{externalProviderType[cell.getValue()] || cell.getValue()}</div>,
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        header: 'Сумма',
        accessorKey: 'amount',
        Cell: ({ row }) => row.original.amount,
        enableColumnFilterModes: false,
      },
      {
        header: 'Название Игры',
        accessorKey: 'externalGameId',
        Cell: ({ row }) => row.original.externalGame?.name,
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        header: 'Внешняя транзакция',
        accessorKey: 'externalTransaction',
        Cell: ({ row: {original} }) => <div style={{maxWidth: 500}}>
          {original.externalTransaction ? JSON.stringify(original.externalTransaction) : null}
        </div>,
        enableColumnFilterModes: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: 'ID',
        accessorKey: '_id',
        enableColumnFilterModes: false,
      },
    ],
    [],
  );

  const defaultFilters = useMemo(() => {
    return columns.reduce(
      (acc, column) => {
        const searchQueryFilter = searchQueryFilters[column.accessorKey];
        if (searchQueryFilter) {
          acc.push({
            id: column.accessorKey,
            value: searchQueryFilter,
          });
        }
        return acc;
      },
      [] as { id: string; value: any }[],
    );
  }, [searchQueryFilters]);

  const isPersonalTransactions = userId && userId !== '0';

  const classes = useStyles();
  const request = useAuthAPI();

  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [columnFilters, setColumnFilters] = useState(defaultFilters);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: MIN_PAGE_SIZE,
  });
  const [cursorPagination, setCursorPagination] = useState({
    cursor: null,
    prevCursor: null,
    nextCursor: null,
  });

  const debouncedColumnFilters = useDebounce(columnFilters, 700);

  const getTransactions = async () => {
    try {
      setIsLoading(true);

      const sort = sorting[0] as any;
      const orderQuery = sort
        ? { orderBy: sort?.id, orderDirection: sort?.desc ? 'desc' : 'asc' }
        : {};

      const filterByUserId = isPersonalTransactions ? { userId } : {};
      const filters = getFilters(columnFilters);

      URLHelper.replaceSearchParams(qs.stringify({ ...filters, pageSize: pagination.pageSize }));

      const { data: remoteData } = await request(endpoints.transactions, {
        limit: pagination.pageSize,
        cursor: cursorPagination.cursor,
        ...orderQuery,
        ...filters,
        ...filterByUserId,
      });
      setData(remoteData.transactions);
      setCursorPagination({
        ...cursorPagination,
        prevCursor: remoteData.prevCursor,
        nextCursor: remoteData.nextCursor,
      });
      setIsError(false);
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onFirstPageClick = () => {
    setCursorPagination({
      ...cursorPagination,
      cursor: null,
    });
  };

  const onPrevPageClick = () => {
    setCursorPagination({
      ...cursorPagination,
      cursor: cursorPagination.prevCursor,
    });
  };
  const onNextPageClick = () => {
    setCursorPagination({
      ...cursorPagination,
      cursor: cursorPagination.nextCursor,
    });
  };

  useEffect(() => {
    getTransactions();
  }, [debouncedColumnFilters, pagination.pageSize, sorting, cursorPagination.cursor]);

  return (
    <div>
      {userId !== '0' && <TransactionsStatsByDays userId={userId} />}
      <CreateTransactionDialog
        userId={userId}
        open={openCreateDialog}
        onHandleClose={(data) => {
          setOpenCreateDialog(false);
          if (data) getTransactions();
        }}
      />
      <ImportJackStatsDialog
        open={openImportStatsDialog}
        onHandleClose={() => {
          setOpenImportStatsDialog(false);
        }}
        onSuccess={() => {}}
      />
      <GridContainer>
        {/* @ts-ignore */}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>
                Управление транзакциями {isPersonalTransactions ? userId : undefined}
              </p>
              {isPersonalTransactions && user && (
                <>
                  <span className={classes.balance}>Баланс пользователя:</span>
                  <span>{user.balance.toFixed(2)}</span>
                </>
              )}
            </CardHeader>
            <CardBody>
              <MaterialReactTable
                // @ts-ignore
                columns={columns}
                data={data}
                getRowId={(row) => row._id}
                initialState={{
                  showColumnFilters: true,
                }}
                enableMultiSort={false}
                manualFiltering
                manualPagination
                manualSorting
                enableGlobalFilter={false}
                muiToolbarAlertBannerProps={
                  isError
                    ? {
                        color: 'error',
                        children: 'Error loading data',
                      }
                    : undefined
                }
                onColumnFiltersChange={setColumnFilters}
                onSortingChange={setSorting}
                rowCount={-1}
                enableColumnFilterModes
                localization={MRT_Localization_RU}
                enableToolbarInternalActions
                muiTablePaginationProps={() => ({
                  component: () => (
                    <div>
                      <IconButton aria-label="delete" onClick={onFirstPageClick}>
                        <FirstPageIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        disabled={!cursorPagination.prevCursor}
                        onClick={onPrevPageClick}
                      >
                        <ChevronLeftIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        disabled={!cursorPagination.nextCursor}
                        onClick={onNextPageClick}
                      >
                        <ChevronRightIcon />
                      </IconButton>
                    </div>
                  ),
                })}
                state={{
                  columnFilters,
                  isLoading,
                  showAlertBanner: isError,
                  sorting,
                }}
                renderTopToolbar={({ table }) => {
                  return (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Box>
                        {isPersonalTransactions && (
                          <IconButton onClick={() => setOpenCreateDialog(true)}>
                            <AddIcon />
                          </IconButton>
                        )}
                        <IconButton onClick={() => setOpenImportStatsDialog(true)}>
                          <ReceiptIcon />
                        </IconButton>
                        <MRT_ShowHideColumnsButton table={table} />
                        <MRT_FullScreenToggleButton table={table} />
                        <MRT_ToggleDensePaddingButton table={table} />
                        <MRT_ToggleFiltersButton table={table} />
                      </Box>
                    </Box>
                  );
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Transactions;
