import endpoints from 'api/endpoints';
import { api } from 'api/rest';

export class TournamentService {
  static async findAll() {
    const response = await api.get(endpoints.getTournaments);
    return response;
  }

  static async create(data: Record<string, any>) {
    const response = await api.post(endpoints.tournaments, data);
    return response;
  }

  static async getByID(id: string) {
    const response = await api.get(`${endpoints.tournaments}/info/${id}`);
    return response;
  }

  static async updateByID(id: string, data: Record<string, any>) {
    const response = await api.put(`${endpoints.tournaments}/${id}`, data);
    return response;
  }

  static async deleteByID(id: string) {
    const response = await api.delete(`${endpoints.tournaments}/${id}`);
    return response;
  }

  static async cancelByID(id: string) {
    const response = await api.post(`${endpoints.tournaments}/cancel-tournament/${id}`);
    return response;
  }
}
