export enum ETransactionTarget {
  REAL = 1,
  COINS = 2,
  PLAY = 3,
  REFERRAL_BONUS = 4,
  REFERRAL_REVENUE_SHARE = 5,
  RAKE_BACK = 6,
}

export enum ETransactionType {
  GAME_BET = 1,
  GAME_WIN = 2,
  DIRECT_CHANGE = 3,
  EVENBET_CREDIT = 4,
  EVENBET_DEBIT = 5,
  EVENBET_ROLLBACK = 6,
  JACKPOKER_GET_CASH = 7,
  JACKPOKER_RETURN_CASH = 8,
  ALGNET_CREDIT = 9,
  ALGNET_DEBIT = 10,
  WITHDRAWAL_CREDIT = 11,
  CYPIX_CREDIT = 12,
  CYPIX_DEBIT = 13,
  CASSY_DEBIT = 14,
  XPROCESSING_CREDIT = 15,
  XPROCESSING_DEBIT = 16,
  PAYCOS_CREDIT = 17,
  PAYCOS_DEBIT = 18,
  WITHDRAWAL_REJECTED = 19,
  DEPOSIT = 20,
  WITHDRAWAL = 21,
  INVITE_CANCEL = 22,
  RANK_BONUS = 23,
  RAKE_BACK = 24,
  RAKE_BACK_MINUS = 25,
  WITHDRAWAL_REJECTED_RETURN_BALANCE = 26,
  DEBIT = 27,
  CREDIT = 28,
  RAKE = 29,
  PAYLLION_DEBIT = 30,
}
